import "./style.css";

const Policy = () => {
  return (
    <div className="flex flex-col">
      <div class="privacy-policy">
        <h2>Chính Sách Bảo Mật</h2>

        <p>
          Ứng dụng <strong>vexe.vn</strong> do chúng tôi phát triển và vận hành. Chúng
          tôi tôn trọng quyền riêng tư của người dùng và cam kết bảo vệ thông
          tin cá nhân của bạn. Chính sách bảo mật này giải thích cách chúng tôi
          thu thập, sử dụng và bảo vệ dữ liệu của bạn.
        </p>

        <div class="section">
          <h3>1. Thông tin chúng tôi thu thập</h3>
          <p>
            Khi bạn sử dụng ứng dụng <strong>vexe.vn</strong>, chúng tôi
            có thể thu thập các loại thông tin sau:
          </p>
          <ul>
            <li>
              Thông tin cá nhân: Họ tên, số điện thoại, email (nếu bạn đăng ký
              tài khoản).
            </li>
            <li>
              Dữ liệu thiết bị: Địa chỉ IP, phiên bản hệ điều hành, loại thiết
              bị.
            </li>
            <li>Dữ liệu vị trí (nếu bạn cho phép).</li>
          </ul>
        </div>

        <div class="section">
          <h3>2. Cách chúng tôi sử dụng thông tin</h3>
          <p>Thông tin thu thập được sử dụng để:</p>
          <ul>
            <li>Cung cấp và cải thiện dịch vụ.</li>
            <li>Xử lý đơn đặt vé và thanh toán.</li>
            <li>Gửi thông báo và cập nhật quan trọng.</li>
          </ul>
        </div>

        <div class="section">
          <h3>3. Chia sẻ thông tin</h3>
          <p>
            Chúng tôi không chia sẻ thông tin cá nhân của bạn với bên thứ ba,
            ngoại trừ:
          </p>
          <ul>
            <li>Đối tác cung cấp dịch vụ thanh toán.</li>
            <li>Cơ quan pháp luật nếu có yêu cầu hợp lệ.</li>
          </ul>
        </div>

        <div class="section">
          <h3>4. Quyền của bạn</h3>
          <p>
            Bạn có thể yêu cầu xem, sửa hoặc xóa thông tin cá nhân của mình bằng
            cách liên hệ với chúng tôi qua <strong>pkdvexe.vn@gmail.com</strong>.
          </p>
        </div>

        <div class="section">
          <h3>5. Bảo mật dữ liệu</h3>
          <p>
            Chúng tôi áp dụng các biện pháp bảo mật như mã hóa dữ liệu và tường
            lửa để bảo vệ thông tin cá nhân khỏi truy cập trái phép.
          </p>
        </div>

        <div class="section">
          <h3>6. Thay đổi chính sách</h3>
          <p>
            Chính sách bảo mật này có thể được cập nhật theo thời gian. Chúng
            tôi sẽ thông báo nếu có thay đổi quan trọng.
          </p>
        </div>

        <p>
          <em>
            Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với chúng tôi qua{" "}
            <strong>pkdvexe.vn@gmail.comm</strong>.
          </em>
        </p>
      </div>
    </div>
  );
};

export default Policy;
